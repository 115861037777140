import { AxiosError } from 'axios';
import { API_USR_CTRACKER, API_PSW_CTRACKER } from 'shared/api/config';
import APIClientCTracker from 'shared/api/apiCTracker';
import {
  IClaim,
  IClaimGallery,
  IRequestForm,
  IClaimBarCharts,
  IClaimPercentage,
  IClaimPieChart,
  IClaimStatistics,
} from 'types/claimsTracker';
import { EstimatedAmounts } from 'components/claims/estimated';
import { AdjustAmounts } from 'components/claims/adjust';
import { ExpenseFields } from 'components/claims/expense';
import { Depreciation } from 'components/claims/depreciation';
import { DepreciationCalculation } from 'components/claims/depreciationCalculation';
import { Rol } from 'types/common';
import { PartFields } from 'components/claims/autoParts';
import { endpoints } from 'shared/api';

export default class ClaimTrackerClient {
  async requestToken(): Promise<string> {
    const data = {
      username: API_USR_CTRACKER,
      password: API_PSW_CTRACKER,
    };

    const result = await APIClientCTracker.post(
      endpoints.POST_AUTHORIZATION,
      data,
    );

    return result.data.data;
  }

  async getClaim(UId: string): Promise<IClaim> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.get(
      endpoints.GET_CLAIMS.replace('{id}', UId),
      defaultHeader,
    );

    return result.data as IClaim;
  }
  // eslint-disable-next-line
  async updateClaim(data: any) {
    try {
      const token = await this.requestToken();
      const authorization = `Bearer ${token}`;
      const defaultHeader = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authorization,
        },
      };

      const resultUpdate = await APIClientCTracker.put(
        endpoints.POST_PUT_CLAIMS,
        data,
        defaultHeader,
      );

      return (resultUpdate.data.success as boolean) ? 'success' : 'unsuccess';
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        const errorCodes = error.response?.data?.errorCodes;
        if (errorCodes && Array.from(errorCodes).length > 0) {
          // eslint-disable-next-line no-console
          console.log(errorCodes[0]);

          return errorCodes[0];
        }
      }

      return 'unsuccess';
    }
  }

  async setNextStatusByClaim(UId: string): Promise<string> {
    if (UId.length === 0) {
      // eslint-disable-next-line no-console
      console.log('The UID is required.');

      return 'unsuccess';
    }

    try {
      const token = await this.requestToken();
      const authorization = `Bearer ${token}`;
      const defaultHeader = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authorization,
        },
      };

      const resultUpdate = await APIClientCTracker.put(
        endpoints.UPDATE_CLAIMS_STATUS.replace('{id}', UId),
        null,
        defaultHeader,
      );

      // eslint-disable-next-line no-console
      console.log('Claim Status update result :: ', resultUpdate.data);

      return (resultUpdate.data.success as boolean) ? 'success' : 'unsuccess';
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data) {
        const errorCodes = error.response?.data?.errorCodes;
        if (errorCodes && Array.from(errorCodes).length > 0) {
          // eslint-disable-next-line no-console
          console.log(errorCodes[0]);

          return errorCodes[0];
        }
      }

      return 'unsuccess';
    }
  }

  async notifyClaimRequest(UId: string): Promise<boolean> {
    if (UId.length === 0) {
      // eslint-disable-next-line no-console
      console.log('The UID is required.');

      return false;
    }

    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const resultNotify = await APIClientCTracker.put(
      endpoints.NOTIFY_CLAIMS_REQUEST.replace('{claimId}', UId),
      null,
      defaultHeader,
    );

    // eslint-disable-next-line no-console
    console.log('Claim Status update result :: ', resultNotify.data);

    return resultNotify.data.success as boolean;
  }

  async addOrUpdateClaimRequest(
    claimRequestId: string | undefined,
    claimUId: string,
    createdBy: string,
    description: string,
    notify = false,
  ): Promise<string> {
    if (claimUId.length === 0 || createdBy.length === 0) {
      // eslint-disable-next-line no-console
      console.log('The UID or the username creator is required.');

      return '';
    }

    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const data = {
      UId: claimRequestId,
      ClaimUId: claimUId,
      CreatedBy: createdBy,
      EmisorType: 'AGENT',
      Description: description,
      Notify: notify,
    };

    if (claimRequestId && claimRequestId.length > 0) {
      const resultUpdate = await APIClientCTracker.put(
        endpoints.POST_PUT_CLAIMS_REQUEST,
        data,
        defaultHeader,
      );

      // eslint-disable-next-line no-console
      console.log('Claim request update result :: ', resultUpdate.data);

      return claimRequestId;
    } else {
      const result = await APIClientCTracker.post(
        endpoints.POST_PUT_CLAIMS_REQUEST,
        data,
        defaultHeader,
      );

      return result.data.uId;
    }
  }

  async removeClaimRequest(UId: string): Promise<string> {
    if (UId.length === 0) {
      // eslint-disable-next-line no-console
      console.log('The UID is required.');

      return '';
    }
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.delete(
      endpoints.DELETE_CLAIMS_REQUEST.replace('{id}', UId),
      defaultHeader,
    );

    // eslint-disable-next-line no-console
    console.log('Claim request deleted result :: ', result.data);

    return UId;
  }
  async getDiagramsSubrogation(): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.get(
      endpoints.GET_SUBROGATION_SECCIONS,
      defaultHeader,
    );

    return result.data;
  }
  async getAllClaimPaymentAmount(
    claimId: number,
    claimNumber: string,
  ): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.get(
      endpoints.GET_HISTORIC_CLAIM_PAYMENT_AMOUNT.replace(
        '{id}',
        String(claimId),
      ) +
        '?claimNumber=' +
        claimNumber,
      defaultHeader,
    );

    return result.data;
  }

  async createClaimPaymentAmount(
    claimId: number,
    amount: number,
    estimatedAmount: number,
    depreciationAmount: number,
    deductibleAmount: number,
  ): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.post(
      endpoints.POST_PAYMENT_AMOUNT,
      {
        claimId,
        amount,
        estimatedAmount,
        depreciationAmount,
        deductibleAmount,
      },
      defaultHeader,
    );

    return result.data;
  }

  async getFiles(uId: string): Promise<IClaimGallery[]> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const result = await APIClientCTracker.get(`/v1/claims/${uId}/gallery`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    });

    return result.data as IClaimGallery[];
  }

  async getClaimTracker(ClaimUId: string): Promise<IRequestForm> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.get(
      endpoints.GET_CLAIMS_FORM.replace('{claimId}', ClaimUId),
      defaultHeader,
    );

    return result.data as IRequestForm;
  }

  async getAllEstimatedAmount(claimUId?: string): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.get(
      endpoints.GET_CLAIMS_ESTIMATED.replace('{id}', claimUId ?? ''),
      defaultHeader,
    );

    return result?.data;
  }

  async SaveEstimatedAmount(
    data: EstimatedAmounts,
    claimUId?: string,
    fileUrl?: string,
  ): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    // Validate some empty attributes
    if (data.expenseAmount != null && data.expenseAmount === '') {
      data.expenseAmount = '0';
    }

    const result = await APIClientCTracker.post(
      endpoints.POST_CLAIMS_ESTIMATED,
      {
        claimUId,
        userCompanyId: Number(localStorage.getItem('userId')),
        ...data,
        fileUrl,
      },
      defaultHeader,
    );

    return result;
  }

  async DeleteEstimatedAmount(id: number): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.delete(
      endpoints.DELETE_CLAIMS_ESTIMATED.replace('{id}', id.toString()),
      defaultHeader,
    );

    return result;
  }

  async getAllAdjustAmount(
    claimUId?: string,
    isTotalLoss?: boolean,
  ): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.get(
      endpoints.GET_CLAIMS_ADJUST.replace('{id}', claimUId ?? '').replace(
        '{isTotalLoss}',
        isTotalLoss ? 'true' : 'false',
      ),
      defaultHeader,
    );

    return result?.data;
  }

  async SaveAdjustAmount(data: AdjustAmounts, claimUId?: string): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.post(
      endpoints.POST_CLAIMS_ADJUST,
      {
        claimUId,
        userCompanyId: Number(localStorage.getItem('userId')),
        ...data,
      },
      defaultHeader,
    );

    return result;
  }

  async ForwardingMailClaim(
    claimUId: string,
    email: string,
    phone: string,
  ): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.post(
      endpoints.POST_FORWARDING_MAIL.replace('{claimUId}', claimUId),
      {
        email: email,
        phone: phone,
        userId: Number(localStorage.getItem('userId')),
      },
      defaultHeader,
    );

    return result;
  }

  async DeleteAdjustAmount(id: number): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.delete(
      endpoints.DELETE_CLAIMS_ADJUST.replace('{id}', id.toString()),
      defaultHeader,
    );

    return result;
  }

  async getAllExpenses(
    claimUId?: string,
    claimNumber?: string,
    poNumber?: string,
  ): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };
    const endpoint = endpoints.GET_CLAIMS_EXPENSES.replace(
      '{id}',
      claimUId ?? '',
    );
    const result = await APIClientCTracker.get(
      `${endpoint}?claimNumber=${claimNumber}&poNumber=${poNumber}`,
      defaultHeader,
    );

    return result?.data;
  }

  async SaveExpenseAmount(
    data: ExpenseFields,
    claimUId?: string,
  ): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.post(
      endpoints.POST_CLAIMS_EXPENSES,
      {
        claimUId,
        ...data,
      },
      defaultHeader,
    );

    return result;
  }

  async DeleteExpenseAmount(id: number): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.delete(
      endpoints.DELETE_CLAIMS_EXPENSES.replace('{id}', id.toString()),
      defaultHeader,
    );

    return result;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async putClaimStatus(data: any, guid: string): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const endpoint = endpoints.PUT_CLAIM_STATUS.replace('{guid}', guid);
    const result = await APIClientCTracker.put(
      endpoint,
      {
        ...data,
      },
      defaultHeader,
    );

    return result;
  }

  async finalizeAgreement(
    uId: string,
    type: string,
    userId: number,
    eventId: number,
    finishDate: string,
  ): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.put(
      endpoints.PUT_FINALIZE_AGREEMENT.replace('{id}', uId),
      {
        type,
        finishDate,
        userId,
        eventId,
      },
      defaultHeader,
    );

    return result;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async updateAgreementBySupport(uId: string, requestObj: any): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.put(
      endpoints.PUT_UPDATE_AGREEMENT.replace('{id}', uId),
      requestObj,
      defaultHeader,
    );

    return result;
  }

  async applyTotalLoss(
    uId: string,
    isApply: boolean,
    recoveryAmount: number | null,
    updatedReserveAmount: number | null,
    totalLossDate: string | null,
    userId: number,
  ): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.post(
      endpoints.POST_TOTAL_LOSS.replace('{id}', uId),
      {
        isApply,
        recoveryAmount,
        totalLossDate,
        userId,
        updatedReserveAmount,
      },
      defaultHeader,
    );

    return result;
  }

  async applySubrogation(
    uId: string,
    isApply: boolean | null,
    notify: boolean | null,
    amountRecovered: boolean | null,
    recoveryAmount: number | null,
    nonRecoveryCause: string | null,
    subrogationDate: string | null,
    insurance: string | null,
    userId: number,
    diagramId: number | null,
  ): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.post(
      endpoints.POST_SUBROGATION.replace('{id}', uId),
      {
        isApply,
        notify,
        amountRecovered,
        recoveryAmount,
        nonRecoveryCause,
        subrogationDate,
        insurance,
        userId,
        diagramId,
      },
      defaultHeader,
    );

    return result;
  }

  async getPercentageType(
    countryCode: string,
    rolId: number,
    companyId?: number,
    isFilter?: boolean,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    defaultHeader?: any,
  ): Promise<IClaimPieChart[]> {
    const queryParams =
      isFilter || rolId === Rol.INSURER || rolId === Rol.CLAIMS_ASSISTANT
        ? `?countryCode=${countryCode}&rolId=${rolId}&companyId=${companyId}`
        : `?countryCode=${countryCode}&rolId=${rolId}`;
    const endpoint = endpoints.GET_PERCENTAGE_CLAIMANT_TYPE + queryParams;
    const result = await APIClientCTracker.get(endpoint, defaultHeader);

    return result.data;
  }

  async getEventTracking(claimId: string): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Authorization: authorization,
      },
    };
    const endpoint = endpoints.GET_EVENT_TRACKING.replace('{claimId}', claimId);
    const result = await APIClientCTracker.get(endpoint, defaultHeader);

    return result.data;
  }

  async getEventTypes(): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Authorization: authorization,
      },
    };
    const endpoint = endpoints.GET_EVENT_TYPES;
    const result = await APIClientCTracker.get(endpoint, defaultHeader);

    return result.data;
  }

  async saveEventTracking(
    claimId: string,
    eventType: number,
    description: string,
    userId: number,
  ): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Authorization: authorization,
      },
    };
    const endpoint = endpoints.GET_EVENT_TRACKING.replace('{claimId}', claimId);
    const result = await APIClientCTracker.post(
      endpoint,
      { eventType, description, userId },
      defaultHeader,
    );

    return result.data;
  }

  async getAverageStage(
    countryCode: string,
    rolId: number,
    companyId?: number,
    isFilter?: boolean,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    defaultHeader?: any,
  ): Promise<IClaimBarCharts[]> {
    const queryParams =
      isFilter || rolId === Rol.INSURER || rolId === Rol.CLAIMS_ASSISTANT
        ? `?countryCode=${countryCode}&rolId=${rolId}&companyId=${companyId}`
        : `?countryCode=${countryCode}&rolId=${rolId}`;
    const endpoint = endpoints.GET_AVERAGE_STAGE + queryParams;
    const result = await APIClientCTracker.get(endpoint, defaultHeader);

    return result.data;
  }

  async getPercentageStage(
    countryCode: string,
    rolId: number,
    companyId?: number,
    isFilter?: boolean,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    defaultHeader?: any,
  ): Promise<IClaimPercentage[]> {
    const queryParams =
      isFilter || rolId === Rol.INSURER || rolId === Rol.CLAIMS_ASSISTANT
        ? `?countryCode=${countryCode}&rolId=${rolId}&companyId=${companyId}`
        : `?countryCode=${countryCode}&rolId=${rolId}`;
    const endpoint = endpoints.GET_PERCENTAGE_STAGE + queryParams;
    const result = await APIClientCTracker.get(endpoint, defaultHeader);

    return result.data;
  }

  async getClaimStatistics(
    countryCode: string,
    rolId: number,
    companyId?: number,
    isFilter?: boolean,
  ): Promise<IClaimStatistics> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Authorization: authorization,
      },
    };

    const percTypeResult = await this.getPercentageType(
      countryCode,
      rolId,
      companyId,
      isFilter,
      defaultHeader,
    );

    const averagStageResult = await this.getAverageStage(
      countryCode,
      rolId,
      companyId,
      isFilter,
      defaultHeader,
    );

    const percStageResult = await this.getPercentageStage(
      countryCode,
      rolId,
      companyId,
      isFilter,
      defaultHeader,
    );

    const statistics: IClaimStatistics = {
      percentageByType: percTypeResult,
      averageByStage: averagStageResult,
      percentageByStage: percStageResult,
    };
    const queryParams =
      isFilter || rolId === Rol.INSURER || rolId === Rol.CLAIMS_ASSISTANT
        ? `?countryCode=${countryCode}&rolId=${rolId}&companyId=${companyId}`
        : `?countryCode=${countryCode}&rolId=${rolId}`;
    const endpoint = endpoints.GET_PERCENTAGE_STAGE + queryParams;
    const result = await APIClientCTracker.get(endpoint, defaultHeader);

    return statistics;
  }

  async getAllDepreciations(claimUId?: string): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.get(
      endpoints.GET_DEPRECIATIONS.replace('{id}', claimUId ?? ''),
      defaultHeader,
    );

    return result?.data;
  }

  async saveDepreciation(data: Depreciation, claimUId?: string): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.post(
      endpoints.POST_DEPRECIATION,
      {
        claimUId,
        userCompanyId: Number(localStorage.getItem('userId')),
        ...data,
      },
      defaultHeader,
    );

    return result;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async sendDocumentTemplate(data: any): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.post(
      endpoints.POST_SEND_DOCUMENT_TEMPLATE,
      data,
      defaultHeader,
    );

    return result;
  }

  async deleteDepreciation(id: number): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.delete(
      endpoints.DELETE_CLAIMS_DEPRECIATION.replace('{id}', id.toString()),
      defaultHeader,
    );

    return result;
  }

  async getDepreciationCalculate(claimUId?: string): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.get(
      endpoints.GET_DEPRECIATION_CALCULATE.replace('{id}', claimUId ?? ''),
      defaultHeader,
    );

    return result?.data;
  }

  async saveDepreciationCalculate(
    data: DepreciationCalculation,
    claimUId?: string,
  ): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.post(
      endpoints.POST_DEPRECIATION_CALCULATE,
      {
        claimUId,
        userCompanyId: Number(localStorage.getItem('userId')),
        ...data,
      },
      defaultHeader,
    );

    return result;
  }

  async requestApproval(uId: string, resource: string | null): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.post(
      endpoints.POST_APPROVAL.replace('{id}', uId),
      {
        resource,
      },
      defaultHeader,
    );

    return result;
  }

  async getApprovalDashboardByUser(userId?: string): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.get(
      endpoints.GET_APPROVALS_USER.replace('{user}', userId ?? ''),
      defaultHeader,
    );

    return result?.data;
  }

  async getApprovalDashboardByClaimAndUser(
    uId: string,
    user?: string,
  ): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.get(
      `${endpoints.GET_APPROVALS_CLAIM.replace('{id}', uId)}?user=${user}`,
      defaultHeader,
    );

    return result?.data;
  }

  async respondApprovalRequest(
    approvalStepId: number,
    isApproved: boolean,
    rejectReason: string,
  ): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.put(
      endpoints.PUT_APPROVAL.replace(
        '{approvalStepId}',
        approvalStepId ? approvalStepId.toString() : '',
      ),
      {
        isApproved,
        rejectReason,
      },
      defaultHeader,
    );

    return result?.data;
  }

  async getClaimsByCompany(
    countryCode: string,
    rolId: number,
  ): Promise<IClaimBarCharts[]> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };
    const queryParams = `?countryCode=${countryCode}&rolId=${rolId}`;
    const endpoint = endpoints.GET_CLAIM_BY_COMPANY + queryParams;
    const result = await APIClientCTracker.get(endpoint, defaultHeader);

    return result.data;
  }

  async getAllParts(claimUId?: string): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };
    const endpoint = endpoints.GET_CLAIMS_PARTS.replace(
      '{claimUId}',
      claimUId ?? '',
    );

    const result = await APIClientCTracker.get(`${endpoint}`, defaultHeader);

    return result?.data;
  }

  async SavePartAmount(data: PartFields, claimUId?: string): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.post(
      endpoints.POST_CLAIMS_PARTS,
      {
        claimUId,
        ...data,
      },
      defaultHeader,
    );

    return result;
  }

  async DeletePartAmount(id: number): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.delete(
      endpoints.DELETE_CLAIMS_PARTS.replace('{id}', id.toString()),
      defaultHeader,
    );

    return result;
  }

  async notApplyEstimation(guiId: string, isNotApply: boolean): Promise<void> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    await APIClientCTracker.post(
      endpoints.POST_NOT_APPLY_ESTIMATION.replace('{id}', guiId),
      {
        IsNotApply: isNotApply,
        UserId: Number(localStorage.getItem('userId') ?? '0'),
      },
      defaultHeader,
    );
  }

  async reopenClaim(guiId: string, message: string): Promise<any> {
    const token = await this.requestToken();
    const authorization = `Bearer ${token}`;
    const defaultHeader = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
    };

    const result = await APIClientCTracker.post(
      endpoints.POST_REOPEN_CLAIM.replace('{id}', guiId),
      {
        Message: message,
        UserId: Number(localStorage.getItem('userId') ?? '0'),
      },
      defaultHeader,
    );

    return result;
  }
}
