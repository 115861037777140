import React from 'react';
import './index.scss'; // Asegúrate de importar el archivo CSS adecuado

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Button = ({
  children,
  variant = 'primary',
  size = 'sm',
  radius = 'full',
  onClick,
  ...props
}) => {
  const classNames = ['button', variant, size, radius]
    .filter(Boolean)
    .join(' ');

  return (
    <button className={classNames} onClick={onClick} {...props}>
      {children}
    </button>
  );
};

export default Button;
