import React from 'react';
import { Snackbar, Alert as MuiAlert, AlertProps } from '@mui/material';
import styled from 'styled-components';

const CustomAlert = styled(MuiAlert)<
  AlertProps & {
    bgColor?: string;
  }
>`
  background-color: ${(props) => props.bgColor || 'inherit'};
  padding: 16px;
  border-radius: 0px;
  min-width: 326px;

  // Fuente predeterminada
  font-family: 'Connect', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-align: left;
  text-transform: uppercase;
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AlertWithCustomStyles = ({
  showSnackbarPhoto,
  handleSnackBarClearMessagePhoto,
  typeMessagePhoto,
  alertMessagePhoto,
  bgColor,
}) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={showSnackbarPhoto}
      className="vh-side-alert"
    >
      <CustomAlert
        onClose={() => handleSnackBarClearMessagePhoto()}
        severity={typeMessagePhoto}
        variant="filled"
        bgColor={bgColor}
      >
        <div dangerouslySetInnerHTML={{ __html: alertMessagePhoto }} />
      </CustomAlert>
    </Snackbar>
  );
};

export default AlertWithCustomStyles;
