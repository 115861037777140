import { VehicleDamages } from '@connect-technology/connect-react-components-lib';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoIcon from '@mui/icons-material/Info';
import clsx from 'clsx';
import Signature from 'components/common/signature';
import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Field } from 'types/form-field';
import Sketch from './Sketch';
import './styles.scss';
import { getDamages, getDamagesString } from './utils';
import CATALOGS, { getOptionsAutocomplete } from 'static/constants/catalogs';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { siniestrar } from 'context/inspections/actions/actions';
import AudioField from '../audio/audioRecorder';

/* eslint-disable no-case-declarations */
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Radio,
  RadioGroup,
  Chip,
  IconButton,
  Autocomplete,
} from '@mui/material';
import CheckboxList from 'components/common/checkbox-list/CheckboxList';
import Scene from '../scene/Scene';
import { CountryIds } from 'types/common';
import useDevice from 'shared/hooks/useDevice';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import Reinspection from 'components/claims/reinspection/reinspection';

export enum FieldTypes {
  AUDIO = 'audio',
  TEXT = 'text',
  FILLED_TEXT = 'filledtext',
  NUMBER = 'number',
  CHECKBOX = 'checkbox',
  CHECKBOX_LIST = 'checkboxlist',
  RADIOBUTTON = 'radiobutton',
  DISCLAIMER = 'disclaimer',
  DROPDOWN = 'dropdown',
  TEXTAREA = 'textarea',
  SIGNATURE = 'signature',
  STYLIZED_RADIOBUTTONS = 'stylizedradiobuttons',
  SWITCH = 'switch',
  DATE = 'date',
  TIME = 'time',
  DIAGRAM = 'diagram',
  HTMLTEXT = 'htmltext',
  MAP = 'map',
  DAMAGES_DIAGRAM = 'damagesdiagram',
  DYNAMIC_DROPDOWN = 'dynamicdropdown',
  BUTTON = 'button',
  CHIPSTATUS = 'chipstatus',
  REINSPECTION = 'reinspection',
  MULTIDROPDOWN = 'multidropdown',
  AUTOCOMPLETE = 'autocomplete',
}

export enum ValueTypes {
  INTEGER = 'integer',
  STRING = 'string',
  BOOLEAN = 'boolean',
}

interface FieldProps {
  field: Field;
  defaultValue: any;
  isVirtualInspector?: boolean;
  isInspectorForm?: boolean;
  isPreInspection?: boolean;
  isDisabled?: boolean;
  countryId?: number;
  options?: any;
  metadata?: any;
  onChange: (event: any) => void;
  saveFn: (field?: Field) => void;
  hasDefaultValue?: boolean;
  formValidation: (field?: Field, isFinish?: boolean) => boolean;
  handleChangeDiagram?: any;
  isCMT?: boolean;
}

interface SelectedValues {
  label: string;
  value: string;
}

const getSelectedValues = (option: string) => {
  const values = option.split('|');
  const selectedVal: SelectedValues = {
    label: values[1],
    value: values[0],
  };

  return selectedVal;
};

const InputField = ({
  field,
  defaultValue,
  onChange,
  isVirtualInspector,
  saveFn,
  isInspectorForm,
  isPreInspection,
  isDisabled,
  countryId,
  options,
  metadata,
  hasDefaultValue,
  formValidation,
  handleChangeDiagram,
  isCMT,
}: FieldProps): JSX.Element => {
  const [useOnChange, setUseOnChange] = useState(false);
  const { isDesktop } = useDevice();
  const { state: inspectionState } = useContext<IInspectionContext>(
    InspectionContext,
  );

  const allowedTypesFromConfig = ['INTEGERSTR', 'DECIMAL', 'INTEGER'];
  const useSendFieldType =
    (field.sendFieldType &&
      allowedTypesFromConfig.includes(field.sendFieldType.toUpperCase())) ||
    (field.endPoint &&
      allowedTypesFromConfig.includes(field.endPoint.toUpperCase()));

  if (!field.options) {
    field.options = [field.label];
  }

  if (defaultValue === 'null') {
    defaultValue = '';
  }

  if (isPreInspection) {
    field.name = field.idName;
    field.label = field.labelName ?? field.label;
    field.type = field.fieldFormType;
    field.isDisabled = !field.enabled;

    const optionValuesStr =
      field.dropDownOptions ??
      field.checkBoxOptions ??
      field.radioButtonOptions;
    field.options =
      optionValuesStr && optionValuesStr?.length > 0
        ? optionValuesStr?.split('¬')
        : [];
  }

  const activeBlur = (): void => {
    if (!field.inactiveBlur || useOnChange) {
      setUseOnChange(false);
      saveFn(field);
    }
  };

  type DebouncedFunction = (...args: any[]) => void;
  const debounce = (func: DebouncedFunction, delay: number) => {
    let timer: NodeJS.Timeout;

    return function (this: any, ...args: any[]) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const activeOnChange = (event: any): any => {
    if (!field.renderType) {
      onChange(event);
    }

    if (field.inactiveBlur) {
      setUseOnChange(true);
    } else {
      setUseOnChange(false);
    }
  };

  const activeDirectChange = (event: any): any => {
    onChange(event);
    setTimeout(() => {
      saveFn(field);
    }, 500);
  };

  const handleBlur = (e: any, newValue: any, field: any) => {
    formValidation();

    if (field.renderType && field.renderType === 'useClaims') {
      onChange(e);
    }

    if (
      e.target &&
      field.value === newValue &&
      newValue !== field.originalValue
    ) {
      activeBlur();
      if (!field.isModifyForAudit) {
        field.originalValue = newValue;
      }
    }
  };

  const debouncedOnChange = useCallback(debounce(activeOnChange, 300), [field]);
  const debouncedOnChangeTextArea = useCallback(debounce(activeOnChange, 100), [
    field,
  ]);

  const activeChangeWithDelay = (e: any, newValue: any, field: any) => {
    formValidation();
    if (
      e.target &&
      e.target.value.length > 0 &&
      newValue !== field.originalValue
    ) {
      setTimeout(() => {
        activeBlur();
        field.originalValue = newValue;
      }, 500);
    }
  };

  const onCustomChange = (event: any) => {
    const fecha = new Date(event);
    const horas = fecha.getHours();
    const minutos = fecha.getMinutes();

    return horas + ':' + minutos;
  };

  const setValueTextinput = (fieldName: string) => {
    switch (fieldName) {
      case 'storyDetail':
        return inspectionState.currentInspection?.storyDetail || '';
      case 'Ocurrido':
        return (
          inspectionState.currentInspection?.InsuredVehicle?.Ocurrido || ''
        );
      case 'AcceptTermsEmail':
        return inspectionState.currentInspection?.AcceptTermsEmail || '';
      case 'VehicleDescription2':
        return (
          inspectionState.currentInspection?.InjuredVehicle
            ?.VehicleDescription2 || ''
        );
      case 'driverName':
        return inspectionState.currentInspection?.driverName || '';
      case 'driverLastName':
        return inspectionState.currentInspection?.driverLastName || '';
      case 'driverId':
        return inspectionState.currentInspection?.driverId || '';
      case 'driverNumLicense':
        return inspectionState.currentInspection?.driverNumLicense || '';
      case 'driverLicenseType':
        return inspectionState.currentInspection?.driverLicenseType || '';
      case 'plateCircunstancesInsured':
        return (
          inspectionState.currentInspection?.plateCircunstancesInsured || ''
        );
      default:
        return defaultValue ?? '';
    }
  };
  const [inputValue, setInputValue] = useState(setValueTextinput(field.name));

  useEffect(() => {
    setInputValue(setValueTextinput(field.name));
  }, [inspectionState]);

  useEffect(() => {
    if (hasDefaultValue) {
      field.value = defaultValue;
      saveFn(field);
    }
  }, []);

  useEffect(() => {
    //Storage Item when another field depends from this one
    if (field.options && field.options[0] === 'BrandCatalog') {
      sessionStorage.setItem('brandSelectedForInspector', inputValue);
    }
  }, []);

  const getAutocompleteOptions = (dropDownOption: string) => {
    return getOptionsAutocomplete(dropDownOption);
  };

  const handleAutocompleteChange = (event, option) => {
    if (field.name === 'thirdVehicleBrand') {
      sessionStorage.setItem('brandSelectedForInspector', option);
    }

    const name = `${field.name}|${field.formSectionId}`;

    const customEvent = {
      target: {
        name,
        value: option,
      },
    };

    setInputValue(option);
    onChange(customEvent);
  };

  switch (field.type?.toLowerCase()) {
    case FieldTypes.CHECKBOX:
      return (
        <>
          {field.options.map((option: string) => (
            <FormControlLabel
              key={option}
              control={
                <Checkbox
                  name={`${field.name}|${field.formSectionId}`}
                  onChange={activeOnChange}
                  checked={defaultValue !== '0' && Boolean(defaultValue)}
                  color="primary"
                  onBlur={(e) => handleBlur(e, field.value, field)}
                  disabled={isDisabled}
                />
              }
              label={option}
            />
          ))}
        </>
      );
    case FieldTypes.CHECKBOX_LIST: {
      return (
        <CheckboxList
          name={field.dataType}
          checkList={field.options[0]}
          checkData={defaultValue}
          onChange={activeOnChange}
          onBlur={activeBlur}
          isDisabled={isDisabled}
        />
      );
    }
    case FieldTypes.RADIOBUTTON:
      const optValues: SelectedValues[] = [];
      field.options.map((option: string) => {
        const newOption = getSelectedValues(option);
        optValues.push(newOption);

        return true;
      });

      return (
        <>
          <InputLabel key={field.name}>{field.label}</InputLabel>
          <RadioGroup
            aria-label={field.label}
            name={field.label}
            value={defaultValue}
            onChange={activeOnChange}
            row
          >
            {optValues.map((option: SelectedValues) => (
              <FormControlLabel
                className={field.hasError ? 'rdError' : ''}
                key={option.label}
                value={option.value}
                control={
                  <Radio
                    name={`${field.name}|${field.formSectionId}`}
                    onClick={(e) =>
                      activeChangeWithDelay(e, option.value, field)
                    }
                    disabled={isDisabled}
                    className={field.hasError ? 'radioError' : ''}
                  />
                }
                label={option.label}
              />
            ))}
          </RadioGroup>
        </>
      );
    case FieldTypes.REINSPECTION:
      return (
        <Reinspection eventId={inspectionState.currentInspection?.EventId} />
      );
    case FieldTypes.STYLIZED_RADIOBUTTONS:
      const optionValues: SelectedValues[] = field.options.map(
        (option: string) => getSelectedValues(option),
      );
      const getSelectedClass = (value: any) =>
        defaultValue === value ? 'inspectionForm__toggle-button--selected' : '';

      const getOptionsValues = () =>
        optionValues.map((option: SelectedValues) => (
          <Button
            key={option.value}
            name={`${field.name}|${field.formSectionId}`}
            value={option.value}
            variant="outlined"
            disableElevation
            onClick={activeDirectChange}
            className={clsx(
              field.hasError
                ? 'inspectionForm__toggle-button-error'
                : 'inspectionForm__toggle-button',
              getSelectedClass(option.value),
            )}
            onBlur={(e) => {
              if (!options?.fromThirds) {
                handleBlur(e, option.value, field);
              }
            }}
            disabled={isDisabled}
          >
            {option.label}
          </Button>
        ));

      const direction = isInspectorForm ? 'column' : 'row';

      return (
        <Grid
          container
          justifyContent="flex-start"
          direction={direction}
          spacing={2}
          style={{ marginTop: 5, padding: 8 }}
        >
          <Grid item style={{ minWidth: 149, padding: 8 }}>
            <InputLabel key={field.name} style={{ marginRight: 15 }}>
              {field.label}
            </InputLabel>
          </Grid>
          {isInspectorForm || !isDesktop ? (
            <Grid item>{getOptionsValues()}</Grid>
          ) : (
            getOptionsValues()
          )}
        </Grid>
      );
    case FieldTypes.DROPDOWN:
    case FieldTypes.MULTIDROPDOWN:
      const isMultiDropdown =
        field.fieldFormType === FieldTypes.MULTIDROPDOWN.toUpperCase();
      let selectValues: SelectedValues[] = [];
      selectValues = getOptionsByTemplate(field.options, metadata);
      const checkDefaultValue =
        isMultiDropdown && defaultValue && typeof defaultValue === 'string'
          ? (defaultValue as string).split(',')
          : defaultValue;

      return (
        <FormControl
          variant="outlined"
          className={clsx('inspectionForm__input')}
        >
          <InputLabel
            key={field.name}
            style={{
              // Fixes select label drawn with input border
              backgroundColor: 'white',
              paddingRight: 5,
              paddingLeft: 5,
            }}
          >
            {field.label}
          </InputLabel>
          <Select
            key={field.formSectionId}
            onChange={activeOnChange}
            defaultValue={setValueTextinput(field.name)}
            onBlur={(e) => handleBlur(e, field.value, field)}
            name={`${field.name}|${field.formSectionId}`}
            disabled={isDisabled}
            error={field.hasError ? true : false}
            value={isMultiDropdown ? checkDefaultValue ?? [] : undefined}
            multiple={isMultiDropdown}
            displayEmpty={isMultiDropdown}
            renderValue={
              checkDefaultValue == null || checkDefaultValue.length === 0
                ? () => 'SELECCIONE'
                : undefined
            }
          >
            {selectValues.map((optionVal: SelectedValues, index: number) => (
              <MenuItem
                key={`${index}-${optionVal.label}`}
                value={optionVal.value}
              >
                {optionVal.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case FieldTypes.DYNAMIC_DROPDOWN:
      const selectDynamicValues: SelectedValues[] = [];
      const map = new Map();
      field.options.forEach((option: string) => {
        const newOption = getSelectedValues(option);
        if (!map.has(newOption.value)) {
          map.set(newOption.value, true);
          selectDynamicValues.push(newOption);
        }
      });

      return (
        <FormControl
          variant="outlined"
          className={clsx('inspectionForm__input')}
        >
          <InputLabel
            key={field.name}
            style={{
              // Fixes select label drawn with input border
              backgroundColor: 'white',
              paddingRight: 5,
              paddingLeft: 5,
            }}
          >
            {field.label}
          </InputLabel>
          <Select
            key={`${field.name}-${field.value}`}
            onChange={activeOnChange}
            defaultValue={defaultValue ?? ''}
            onBlur={(e) => handleBlur(e, field.value, field)}
            name={`${field.name}|${field.formSectionId}`}
            disabled={isDisabled}
            error={field.hasError ? true : false}
          >
            {selectDynamicValues.map(
              (option: SelectedValues, index: number) => (
                <MenuItem key={`${index}-${option.label}`} value={option.value}>
                  {option.label}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
      );
    case FieldTypes.DISCLAIMER:
      return <p>{field.contentText}</p>;
    case FieldTypes.AUDIO:
      return (
        <AudioField Field={field} save={saveFn} onChange={activeOnChange} />
      );
    case FieldTypes.BUTTON: {
      const isDisabled =
        String(field.contentText) === 'siniestrar' &&
        metadata.inspectionState?.currentInspection?.EnableClaimsTracker;

      return (
        <Button
          key="btnEdit"
          name="btnEdit"
          variant="outlined"
          disabled={isDisabled}
          className={clsx('settingsForm__toggle-button')}
          onClick={() => getAction(String(field.contentText), metadata)}
        >
          {field.label}
        </Button>
      );
    }
    case FieldTypes.HTMLTEXT:
      const letterText = String(field.contentText);

      return (
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: letterText }}
        ></div>
      );
    case FieldTypes.SIGNATURE:
      return (
        <>
          {isPreInspection && (
            <label className="signature__label">{field.labelName}</label>
          )}
          <Signature
            signature={defaultValue}
            onSignatureChange={(signature) => {
              onChange({
                target: {
                  name: `${field.name}|${field.formSectionId}`,
                  value: signature,
                },
              });
            }}
            onConfirm={saveFn}
            isInspectorForm={isInspectorForm}
            hasError={field.hasError}
            isDisabled={field.isDisabled}
          />
        </>
      );
    case FieldTypes.TEXTAREA:
      return (
        <div>
          <TextField
            key={field.name}
            className={clsx('inspectionForm__input')}
            name={`${field.name}|${field.formSectionId}`}
            label={field.label}
            value={inputValue}
            variant="outlined"
            multiline={true}
            onChange={(event) => {
              setInputValue(event.target.value);
              debouncedOnChangeTextArea(event);
            }}
            onBlur={(e) => handleBlur(e, field.value, field)}
            disabled={isDisabled}
            error={field.hasError ? true : false}
          />
          {field.name === 'storyDetail' && inspectionState.audioPresignedUrl && (
            <div className="flex">
              <div className="icon-info">
                <InfoIcon />
              </div>
              <div className="text-info">
                <span>
                  Recuerda agregar un relato (Obligatorio). Si agregaste un
                  audio puedes transcribirlo.
                </span>
              </div>
            </div>
          )}
        </div>
      );
    case FieldTypes.DIAGRAM:
      return (isInspectorForm || Boolean(isVirtualInspector)) &&
        ([CountryIds.PRI].includes(Number(countryId)) ||
          [CountryIds.CRC].includes(Number(countryId))) ? (
        <Scene editable={Boolean(isInspectorForm)} />
      ) : (
        <Sketch
          isVirtualInspector={Boolean(isVirtualInspector)}
          saveFn={saveFn}
          value={defaultValue}
          onChange={activeOnChange}
          disabled={!isInspectorForm}
          name={`${field.name}|${field.formSectionId}`}
        />
      );
    case FieldTypes.DATE:
      const date =
        defaultValue && defaultValue.split('T')[0]
          ? new Date(defaultValue.split('T')[0] + ' 00:00:00')
          : null;
      const validDate = date instanceof Date && !isNaN(date.getTime());
      let dateValue = validDate && date ? date : null;
      const maxDateProp: any = {};
      let dateChange = false;

      if (field.name === 'eventDateSinister') {
        // Check if the section id is direction sinister
        const eventDateInspection =
          metadata.inspectionState?.currentInspection.eventDateInspection;
        if (eventDateInspection && eventDateInspection.split('T')[0]) {
          maxDateProp.maxDate = new Date(
            eventDateInspection.split('T')[0] + ' 00:00:00',
          );
        }
      }

      if (field.name === 'driverExpireLicenseDate') {
        if (inspectionState.currentInspection?.driverExpireLicenseDate) {
          dateValue = new Date(
            inspectionState.currentInspection?.driverExpireLicenseDate,
          );
        }
      }

      if (field.name === 'driverBirthDate') {
        if (inspectionState.currentInspection?.driverBirthDate) {
          dateValue = new Date(
            inspectionState.currentInspection?.driverBirthDate.split('T')[1]
              ? inspectionState.currentInspection?.driverBirthDate
              : inspectionState.currentInspection?.driverBirthDate +
                'T00:00:00',
          );
        }
      }

      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            maxDate={maxDateProp.maxDate && dayjs(maxDateProp.maxDate)}
            label={field.label}
            value={dateValue !== null ? dayjs(dateValue) : null}
            onClose={() => {
              if (dateChange) {
                saveFn();
              }
            }}
            format={CATALOGS.getCountryFormat().dateTimeFormat}
            disabled={isDisabled}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: 'outlined',
                margin: 'none',
                'aria-label': 'change date',
                error: field.hasError,
              },
            }}
            onChange={(date) => {
              if (date) {
                const dayjsDate = dayjs(date);
                if (dayjsDate.isValid()) {
                  onChange({
                    target: {
                      value: dayjsDate.toISOString(),
                      name: `${field.name}|${field.formSectionId}`,
                    },
                  });
                  dateChange = true;
                }
              }
            }}
          />
        </LocalizationProvider>
      );
    case FieldTypes.TIME:
      const [hour, mins] = defaultValue
        ?.replace(/AM|PM|\s/gi, '')
        ?.split(':') || ['00', '00'];
      const value = hour && mins ? new Date().setHours(hour, mins) : null;
      const [timeChange, setTimeChange] = useState(false);

      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileTimePicker
            label={field.label}
            value={value !== null ? dayjs(value) : null}
            onClose={() => {
              if (timeChange) {
                saveFn();
                setTimeChange(false);
              }
            }}
            disabled={isDisabled}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: 'outlined',
                margin: 'none',
                'aria-label': 'change time',
                error: field.hasError,
                InputProps: {
                  endAdornment: (
                    <IconButton
                      sx={{ marginRight: '-12px' }}
                      disabled={isDisabled}
                    >
                      <AccessTimeIcon />
                    </IconButton>
                  ),
                },
              },
            }}
            onChange={(date) => {
              onChange({
                target: {
                  value: onCustomChange(date),
                  name: `${field.name}|${field.formSectionId}`,
                },
              });
              setTimeChange(true);
            }}
          />
        </LocalizationProvider>
      );
    case FieldTypes.NUMBER:
      return (
        <TextField
          key={field.name}
          className={clsx('inspectionForm__input')}
          name={`${field.name}|${field.formSectionId}`}
          label={field.label}
          defaultValue={inputValue ?? ''}
          variant="outlined"
          onChange={(event) => {
            setInputValue(event.target.value);
            debouncedOnChange(event);
          }}
          onBlur={(e) => handleBlur(e, parseInt(field.value), field)}
          type="number"
          disabled={isDisabled}
          error={field.hasError ? true : false}
        />
      );
    case FieldTypes.MAP:
      return (
        <img
          src={`https://maps.googleapis.com/maps/api/staticmap?center=${
            defaultValue || '18.449815,-66.073410'
          }&zoom=${20}&key=${String(
            process.env.REACT_APP_GMAPS_KEY,
          )}&size=600x400`}
          alt="map location"
        />
      );
    case FieldTypes.DAMAGES_DIAGRAM:
      const damages = getDamages(defaultValue);

      return (
        <div className="damages-container">
          <div className="diagram">
            <VehicleDamages
              damages={damages.forDiagram}
              onChangeDamages={(newDamages) => {
                onChange({
                  target: {
                    name: `${field.name}|${field.formSectionId}`,
                    value: getDamagesString(newDamages, defaultValue),
                  },
                });
                options?.fromThirds && !isCMT
                  ? handleChangeDiagram(true)
                  : saveFn();
              }}
              readonly={isDisabled && !isCMT}
            />
          </div>
          <div className="damage-list">
            <ul>
              {damages.list.length ? (
                damages.list.map((d) => (
                  <li key={d.key}>
                    {d.title} #{d.key} - <b>{d.description.toUpperCase()}</b>
                  </li>
                ))
              ) : (
                <li>No hay daños para mostrar</li>
              )}
            </ul>
          </div>
        </div>
      );
    case FieldTypes.FILLED_TEXT:
      return (
        <TextField
          key={field.name}
          className={clsx('inspectionForm__input')}
          name={`${field.name}|${field.formSectionId}`}
          label={field.label}
          defaultValue={inputValue ?? ''}
          variant="outlined"
          size="small"
          onChange={(event) => {
            setInputValue(event.target.value);
            debouncedOnChange(event);
          }}
          onBlur={(e) => handleBlur(e, field.value, field)}
          disabled={isDisabled}
          error={field.hasError ? true : false}
        />
      );
    case FieldTypes.CHIPSTATUS:
      return (
        <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 chip-container">
          <div className="MuiGrid-root MuiGrid-item chipLabel">
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated">
              {field.label}
            </label>
          </div>
          <Chip
            className={clsx('chipTop')}
            icon={
              defaultValue === 'Validada' ? <DoneIcon /> : <ErrorOutlineIcon />
            }
            label={defaultValue ?? ''}
            clickable
            color={defaultValue === 'Validada' ? 'primary' : 'default'}
          />
        </div>
      );
    case FieldTypes.AUTOCOMPLETE:
      return (
        <Autocomplete
          className={clsx('inspectionForm__input')}
          style={{ marginLeft: '0px' }}
          sx={{ '& .MuiInputBase-root': { height: '55px' } }}
          options={getAutocompleteOptions(field.options[0] ?? '')}
          getOptionLabel={(option) => option}
          onChange={handleAutocompleteChange}
          onBlur={(e) => handleBlur(e, field.value, field)}
          value={inputValue ?? ''}
          renderInput={(params) => (
            <TextField {...params} placeholder={field.label} />
          )}
        />
      );
    case FieldTypes.TEXT:
    default:
      return (
        <>
          {useSendFieldType ? (
            <TextField
              key={field.name}
              className={clsx('inspectionForm__input')}
              name={`${field.name}|${field.formSectionId}`}
              label={field.label}
              type="number"
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                min: 0,
              }}
              defaultValue={inputValue ?? ''}
              variant="outlined"
              onChange={(e) => {
                if (
                  e.target &&
                  field.maxLength &&
                  field.maxLength > 0 &&
                  e.target.value.length > field.maxLength
                ) {
                  e.target.value = e.target.value.slice(0, field.maxLength);
                }
                setInputValue(e.target.value);
                debouncedOnChange(e);
              }}
              onBlur={(e) => handleBlur(e, field.value, field)}
              disabled={isDisabled}
              error={field.hasError ? true : false}
            />
          ) : (
            <TextField
              key={field.name}
              className={clsx('inspectionForm__input')}
              name={`${field.name}|${field.formSectionId}`}
              label={field.label}
              defaultValue={inputValue ?? ''}
              variant="outlined"
              onChange={(event) => {
                setInputValue(event.target.value);
                debouncedOnChange(event);
              }}
              onBlur={(e) => handleBlur(e, field.value, field)}
              disabled={isDisabled}
              error={field.hasError ? true : false}
              inputProps={
                field.maxLength && field.maxLength > 0
                  ? {
                      maxLength: field.maxLength,
                    }
                  : {}
              }
            />
          )}
        </>
      );
  }
};

export default InputField;

const getAction = async (action: string, metadata: any) => {
  switch (action) {
    case 'siniestrar':
      if (!metadata.inspectionState?.currentInspection?.EnableClaimsTracker) {
        await siniestrar(
          metadata.inspectionState,
          metadata.sections,
          metadata.inspectDispatch,
        );
      } else {
        alert(
          'Este evento solo puede ser siniestrado desde el flujo de Reclamaciones.',
        );
      }

      break;
  }
};

const getOptionsByTemplate = (
  options: any[],
  metadata: any,
): SelectedValues[] => {
  const currentValues = Array.from(options);
  const resultValues: SelectedValues[] = [];

  switch (String(currentValues[0]).toUpperCase()) {
    case 'CUSTOM_AE_INSURED':
      const aeInsuredListStr =
        metadata.inspectionState?.currentInspection.InsuredAccompanimentList;
      const aeInsuredList = aeInsuredListStr
        ? String(aeInsuredListStr).split('¬')
        : [];

      aeInsuredList.forEach((option: string) => {
        const newOption = getSelectedValues(option);
        resultValues.push(newOption);
      });
      break;
    case 'CUSTOM_AE_AFFECTED':
      const aeAffectedListStr =
        metadata.inspectionState?.currentInspection.AffectedAccompanimentList;
      const aeAffectedList = aeAffectedListStr
        ? String(aeAffectedListStr).split('¬')
        : [];

      aeAffectedList.forEach((option: string) => {
        const newOption = getSelectedValues(option);
        resultValues.push(newOption);
      });
      break;
    case 'CUSTOM_TYPE_FOLLOWUP':
      const aePaymentExrpressListStr =
        metadata.inspectionState?.currentInspection.FollowUpList;
      const aePaymentExpressList = aePaymentExrpressListStr
        ? String(aePaymentExrpressListStr).split('¬')
        : [];

      aePaymentExpressList.forEach((option: string) => {
        const newOption = getSelectedValues(option);
        resultValues.push(newOption);
      });
      break;
    default:
      currentValues.forEach((option: string) => {
        const newOption = getSelectedValues(option);
        resultValues.push(newOption);
      });
      break;
  }

  return resultValues;
};
